<!--  -->
<template>
  <div class="content">
    <el-row class="part1" type="flex" justify="space-between">
      <el-col :span="24" :md="9" class="col-md9">
        <div class="price border">
          <p class="tit">消费金额</p>
          <div class="money">￥{{ totalMoney }}</div>
          <div class="sum layout-flex-all">
            <div class="item">
              <div class="left">
                <div class="circle">￥</div>
              </div>
              <div class="right">
                <p class="t">本月消费金额</p>
                <p class="n">￥100,000</p>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <div class="circle">￥</div>
              </div>
              <div class="right">
                <p class="t">本月消费金额</p>
                <p class="n">￥100,000</p>
              </div>
            </div>
          </div>
          <div class="tips">
            <router-link
              style="text-decoration: none; margin-left: 10px; color: #0cafcc"
              to="/order"
            >更多消费明细</router-link>
          </div>
        </div>
      </el-col>
      <el-col :span="24" :md="15" class="col-md15">
        <div class="echarts border">
          <p class="tit">24小时调用量统计</p>
          <div id="canvas" ref="canvas"></div>
        </div>
      </el-col>
    </el-row>
    <el-row class="part2" type="flex" justify="space-between">
      <el-col :span="24" :md="15">
        <div class="data border">
          <p class="tit">用户资料</p>
          <div class="inner layout-flex">
            <div class="name">
              {{ memberInfo.nickName }}
              <span>,您好</span>
            </div>
            <div class="img" @click="$router.push('/user/info')">
              <img src="@/assets/img/icon01.png" alt />
              <img src="@/assets/img/icon02.png" alt />
              <img src="@/assets/img/icon03.png" alt />
            </div>
          </div>
          <!-- <div class="money">￥75,000</div> -->
        </div>
        <div class="pack border">
          <p class="tit">快过期、用完的资料包</p>
          <div class="inner">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="date" label="接口名称" align="center"></el-table-column>
              <el-table-column prop="name" label="剩余厘" align="center"></el-table-column>
              <el-table-column prop="address" label="可用天数" align="center"></el-table-column>
            </el-table>
          </div>
          <!-- <div class="money">￥75,000</div> -->
        </div>
      </el-col>
      <el-col :span="24" :md="9">
        <div class="chart border">
          <p class="tit">
            会员统一资源包：
            <span>一个</span>
          </p>
          <div class="inner">
            <div class="circle" ref="circle"></div>
            <p>昨日无调用，无法预估剩余可用天数</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="part3" type="flex" justify="space-between">
      <el-col :span="24">
        <div class="help border">
          <p class="tit">使用帮助</p>
          <!-- <div class="money">￥75,000</div> -->
          <div class="inner">
            <div class="item">1. 新手指导</div>
            <div class="item">2. 常见问题</div>
            <div class="item">3. 接口最佳实践</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { invoke24 } from "@/api/api.js";
import { circleVipTimes } from "@/api/member.js";
import { analyzeData } from "@/api/order.js";
export default {
  data () {
    return {
      tableData: [],
      memberInfo: { nickName: "" },
      lineData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ],
      circleValue: 0,
      circleTotal: 100,
      circleRemain: 0,
      totalMoney: 0,
    };
  },
  created () {
    console.log(
      "created",
      localStorage.getItem("userInfo"),
      JSON.parse(localStorage.getItem("userInfo"))
    );
    if (localStorage.getItem("userInfo")) {
      this.memberInfo = JSON.parse(localStorage.getItem("userInfo"));
      console.log(this.memberInfo);
    }
  },
  mounted () {
    this.drawLine();
    this.drawCircle();
    this.analyzeDataHandler();
  },
  components: {},

  computed: {},

  methods: {
    drawLine () {
      this.lineData = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      let maxNum = 1500;
      invoke24().then((resp) => {
        if (resp.code == 0) {
          this.lineData = resp.data;
          let tempMaxNum = 0;
          this.lineData.forEach((item) => {
            if (item > tempMaxNum) {
              tempMaxNum = item;
            }
          });
          if (tempMaxNum > 0) {
            maxNum = tempMaxNum;
          }
        } else {
          this.lineData = [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ];
        }
        let myChart = this.$echarts.init(this.$refs.canvas);
        myChart.setOption({
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "line", //根据类型不同提示显示的方式不同
              label: {
                backgroundColor: "#ff5f85",
                color: "#ff5f85",
              },
            },
          },
          grid: {
            x: "8%", //x 偏移量
            y: "12%", // y 偏移量
            width: "87%", // 宽度
            height: "74%", // 高度
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false, //不显示坐标轴刻度
            },
            axisLabel: {
              //坐标轴刻度标签的相关设置
              margin: 20, //刻度标签与轴线之间的距离
            },
            data: [
              "00点",
              "01点",
              "02点",
              "03点",
              "04点",
              "05点",
              "06点",
              "07点",
              "08点",
              "09点",
              "10点",
              "11点",
              "12点",
              "13点",
              "14点",
              "15点",
              "16点",
              "17点",
              "18点",
              "19点",
              "20点",
              "21点",
              "22点",
              "23点",
            ],
          },
          yAxis: {
            type: "value",
            axisPointer: {
              snap: true,
            },
            max: maxNum,
            min: 0,
            splitNumber: 3,
            axisLabel: {
              //坐标轴刻度标签的相关设置
              margin: 20, //刻度标签与轴线之间的距离
            },
            // boundaryGap: [0, '30%']
          },
          // visualMap: {
          //   color: 'red'
          // },
          series: [
            {
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#ff5f85",
                width: 3,
              },

              areaStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(255,95,133,0.4)",
                  },
                  {
                    offset: 1,
                    color: "rgba(255,95,133,0)",
                  },
                ]),
              },
              data: this.lineData,
            },
          ],
        });
        window.addEventListener("resize", () => {
          // 自动渲染echarts
          myChart.resize();
        });
      });
    },
    drawCircle () {
      circleVipTimes().then((resp) => {
        if (resp.code == 0) {
          if (resp.data.total > 0) {
            this.circleTotal = resp.data.total;
            this.circleValue = resp.data.percent;
            this.circleRemain = resp.data.remain;
          }
        }
        let myCircle = this.$echarts.init(this.$refs.circle);
        myCircle.setOption({
          series: [
            {
              type: "gauge",
              min: 0,
              max: this.circleTotal,
              axisLine: {
                lineStyle: {
                  width: 20,
                },
              },
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#ff5200",
                },
                {
                  offset: 0.5,
                  color: "#ff8b00",
                },
                {
                  offset: 1,
                  color: "#ff5200",
                },
              ]),
              startAngle: 90,
              endAngle: -270,
              pointer: {
                show: false,
              },
              progress: {
                show: true,
                overlap: false,
                roundCap: true,
                clip: false,
                itemStyle: {
                  // borderWidth: 1,
                  // borderColor: '#ff5200'
                },
              },

              splitLine: {
                show: false,
                distance: 0,
                length: 10,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
                distance: 50,
              },
              data: [
                {
                  value: this.circleRemain,
                  name: "剩余次数",
                  title: {
                    offsetCenter: ["0%", "30%"],
                  },
                  detail: {
                    offsetCenter: ["0%", "-10%"],
                  },
                },
              ],
              title: {
                fontSize: 14,
              },
              detail: {
                width: 50,
                height: 14,
                fontSize: 40,
                color: "auto",
                formatter: "{value}",
              },
            },
          ],
        });
        window.addEventListener("resize", () => {
          // 自动渲染echarts
          myCircle.resize();
        });
      });
    },
    //订单数据分析
    analyzeDataHandler () {
      analyzeData()
        .then((resp) => {
          if (resp.code == 0) {
            this.totalMoney = resp.data.total;
          }
        })
        .catch((e) => {
          console.log(e.msg);
        });
    },
  },
};
</script>
<style lang='scss' scoped>
@mixin tit($bottom: 15px) {
  margin-bottom: $bottom;
  font-size: 28px;
  color: #666;
  // font-weight: bold;
}
@mixin layout-flex() {
  margin-bottom: 20px;
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
}
.content {
  width: 100%;
  .part1 {
    @include layout-flex();
    .col-md9 {
      margin-bottom: 20px;
    }
    .price {
      height: 100%;
      margin-right: 0px;
      padding: 30px;
      background: #fff;
      .tit {
        @include tit;
      }
      .money {
        margin-bottom: 15px;
        font-size: 38px;
        color: #50576a;
      }
      .sum {
        margin-bottom: 30px;
        width: 100%;
        .item {
          padding: 20px 0;
          width: 48%;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          &:nth-of-type(1) {
            background: #ff587f;
            .left {
              .circle {
                color: #ff587f;
              }
            }
          }
          &:nth-of-type(2) {
            background: #9582ec;
            .left {
              .circle {
                color: #9582ec;
              }
            }
          }
          .left {
            .circle {
              margin-right: 10px;
              width: 28px;
              height: 28px;
              text-align: center;
              line-height: 28px;
              border-radius: 50%;
              font-size: 15px;
              background: #fff;
            }
          }
          .right {
            .t {
              font-size: 14px;
              margin-bottom: 4px;
            }
            .n {
              font-size: 22px;
            }
          }
        }
      }
      .tips {
        color: #0cafcc;
      }
    }
    .echarts {
      height: 100%;
      padding: 30px;
      background: #fff;
      .tit {
        @include tit;
      }
      #canvas {
        width: 100%;
        height: 250px;
      }
    }
  }

  .part2 {
    @include layout-flex();
    .data {
      margin: 0 0 20px 0;
      background: #fff;
      .tit {
        @include tit(0);
        padding: 15px;
        border-bottom: 1px solid #ebeef5;
      }
      .inner {
        padding: 15px;
        .name {
          margin-right: 20px;
          color: #999;
          font-size: 14px;
        }
        .img {
          img {
            margin: 0 10px;
            width: 40px;
          }
        }
      }
    }
    .pack {
      margin: 0 0 20px 0;
      background: #fff;
      .tit {
        @include tit(0);
        padding: 15px;
        border-bottom: 1px solid #ebeef5;
      }
      .inner {
        padding: 15px;
        & ::v-deep .el-table th,
        .el-table tr {
          background-color: #f9f9f9;
          color: #0cafcc;
        }
      }
    }
    .chart {
      height: 100%;
      background: #fff;
      .tit {
        @include tit(0);
        padding: 15px;
        border-bottom: 1px solid #ebeef5;
        span {
          color: #ff8f00;
        }
      }
      .inner {
        padding: 15px;
        width: 100%;
        height: 100%;
        .circle {
          width: 100%;
          height: 250px;
        }
        p {
          margin-top: -20px;
          font-size: 14px;
          color: #ff8e00;
        }
      }
    }
  }
  .part3 {
    @include layout-flex();
    .help {
      background: #fff;
      .tit {
        @include tit(0);
        padding: 15px;
        border-bottom: 1px solid #ebeef5;
      }
      .inner {
        padding: 15px;
        .item {
          height: 38px;
          font-size: 15px;
          color: #333333;
          line-height: 38px;
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}
@media screen and (min-width: 992px) {
  .content {
    .part1 {
      .col-md9 {
        margin-bottom: 0;
      }
      .price {
        margin-right: 20px;
      }
    }
    .part2 {
      .data {
        margin-right: 20px;
      }
      .pack {
        margin: 0 20px 0px 0;
      }
    }
  }
}
</style>