import request from "./request"

//修改手机号
export const updatePhone = (data) => {
    return request("post", "/api/member/member/updatePhone", data);
}

//修改邮箱
export const updateMail = (data) => {
    return request("post", "/api/member/member/updateMail", data);
}

//会员接口次数
export const circleVipTimes = () => {
    return request("get", "/api/member/member/circleVipTimes");
}