import request from "./request"

//接口分页显示
export const apiPage = (page) => {
    return request("get", "/api/member/memberInterface/page", page);
}

//接口详情
export const apiInfo = (id) => {
    return request("get", "/api/member/memberInterface/info", { id: id });
}

//获取秘钥信息
export const secretInfo = () => {
    return request("get", "/api/system/apiSecret/get");
}

//接口分页显示
export const apiLogPage = (page) => {
    return request("get", "/api/log/logOpenapi/page", page);
}

//接口24小时调用量显示
export const invoke24 = () => {
    return request("get", "/api/log/logOpenapi/invoke24");
}